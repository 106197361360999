<template>
    <round />
</template>
<script>
import round from '@/components/student/round/index.vue'

export default {
  name: 'roundIndex',
  components: {
    round
  },
}
</script>
