<template>
<div>
    <div class="col" v-if="question" v-show="!isFinishRound">
        <!-- Nav -->
        <div class="row justify-content-center">
            <div class="dtema col col-sm-12 col-md-8 col-lg-6 col-xl-6">
                <div class="row justify-content-between">
                    <router-link :to="{ name: 'Courses' }" class="col- dtema_icon align-self-center">
                        <i class="fas fa-chevron-left"></i>
                    </router-link>

                    <div class="col text-center align-self-center nav_title">
                        <h1>
                            {{ round.course.name }} <br>
                            {{ round.team.name }}
                        </h1>
                    </div>
                    <scoreHamburger />
                </div>
            </div>
        </div>
        <div>
        </div>
        <!-- Progress -->
        <div class="row py-2">
            <div class="mx-auto col col-sm-12 col-md-8 col-lg-6 col-xl-6">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" v-bind:style="{ width: progressBar + '%' }" v-bind:aria-valuenow="progressBar" aria-valuemin="0" aria-valuemax="100">
                        {{ progressBar }} %
                    </div>
                </div>
            </div>
        </div>
        <!-- Favorites -->
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6" style="font-size: 16px; text-align: right; margin-right: 10px; cursor: pointer;">
                <div v-if="myFavorite" v-on:click="daleteFavorite" :style="heartColor.favorite">
                    <i class="fas fa-heart favorite"></i>
                    {{ getFavorite.length }}
                </div>
                <div v-else v-on:click="addFavorite" :style="heartColor.unfavorite">
                    <i class="fas fa-heart favorite"></i>
                    {{ getFavorite.length }}
                </div>
            </div>
        </div>
        <!-- Time -->
        <div class="row py-2">
            <div class="col text-center">
                <span>{{this.responseTime}}</span>
            </div>
        </div>
        <!-- Question -->
        <div class="row py-4 justify-content-center">
            <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-left">
                <div v-html="question.body"></div>
            </div>
        </div>
        <!-- Year -->
        <div class="row py-4 justify-content-center">
            <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 text-right">
                <p class="text-muted">{{ question.block.name }} - {{ question.year.name }}</p>
            </div>
        </div>
        <!-- Answer -->
        <div class="row justify-content-center">
            <div class="col-11 col-sm-12 col-md-8 col-lg-6 col-xl-6 ">
                <input 
                    type="button" 
                    class="btn_long button_click text-center" 
                    v-if="questionOptions.a != 'sinClave'" 
                    :disabled="bloquedAnswers" 
                    :value="questionOptions.a" 
                    v-on:click="selectAnswer(questionOptions.a)" 
                    :style="inputsbackgroundColor.a"/>
                <input 
                    type="button" 
                    class="btn_long button_click text-center" 
                    v-if="questionOptions.b != 'sinClave'" 
                    :disabled="bloquedAnswers" 
                    :value="questionOptions.b" 
                    v-on:click="selectAnswer(questionOptions.b)" 
                    :style="inputsbackgroundColor.b"/>
                <input 
                    type="button" 
                    class="btn_long button_click text-center" 
                    v-if="questionOptions.c != 'sinClave'" 
                    :disabled="bloquedAnswers" 
                    :value="questionOptions.c" 
                    v-on:click="selectAnswer(questionOptions.c)" 
                    :style="inputsbackgroundColor.c"/>
                <input 
                    type="button" 
                    class="btn_long button_click text-center" 
                    v-if="questionOptions.d != 'sinClave'" 
                    :disabled="bloquedAnswers" 
                    :value="questionOptions.d" 
                    v-on:click="selectAnswer(questionOptions.d)" 
                    :style="inputsbackgroundColor.d"/>
                <input 
                    type="button" 
                    class="btn_long button_click text-center" 
                    v-if="questionOptions.e != 'sinClave'" 
                    :disabled="bloquedAnswers" 
                    :value="questionOptions.e" 
                    v-on:click="selectAnswer(questionOptions.e)" 
                    :style="inputsbackgroundColor.e"/>
            </div>
        </div>

        <!-- AnswerGiven -->
        <div class="row py-4 justify-content-center">
            <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-4 button_actions" v-if="typeAnswerGiven === 'CorrectAnswer'">
                <div class="button_actions_icon"><i class="fas fa-check"></i></div>
                <div> Respuesta Correcta. </div>
            </div>
            <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-4 button_actions" v-else-if="typeAnswerGiven === 'WrongAnswer'">
                <div class="button_actions_icon"><i class="fas fa-times"></i></div>
                <div> Respuesta Incorrecta. </div>
            </div>
            <div class="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-4 button_actions" v-else-if="typeAnswerGiven === 'TimeOver'">
                <div class="button_actions_icon"><i class="fas fa-exclamation-triangle"></i></div>
                <div> El tiempo se ha terminado. </div>
            </div>
        </div>

        <!-- Actions -->
        <div class="row py-2 justify-content-center" v-show="canNextQuestion">
            <div class="col">
                <div class="row justify-content-center">
                    <div class="col- button_actions button_click" v-on:click="showComments">
                        <div class="button_actions_icon"><i class="fas fa-envelope"></i></div>
                        <div> Comentar </div>
                        <div class="button_actions_icon" v-if="question.comments_count > 0">
                            <b>·</b> {{ question.comments_count }}
                        </div>
                    </div>
                    <div class="col- button_actions button_click" v-on:click="onNext">
                        <div>Siguiente </div>
                        <div class="button_actions_icon"><i class="fas fa-arrow-right"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Comments -->
        <div class="row py-4 justify-content-center" v-show="showFieldComment">
            <div class="col-11 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                <!-- Comment TextArea-->
                <div class="row py-2">
                    <div class="col">
                        <div class="row">
                            <div class="col-" hidden>
                                <a href="">
                                    <div class="dCirculo">
                                        <img class="imgAvatar" src="/uploads/avatars/2019-06-19-16-58-14.jpg" />
                                    </div>
                                </a>
                            </div>
                            <!-- Textares -->
                            <div class="col text-center">
                                <form>
                                    <div class="form-group">
                                        <textarea maxlength="200" class="form-control" v-model="message" placeholder="Agrega un comentario..." rows="2"></textarea>
                                    </div>
                                    <button type="button" v-on:click="onSendComment" class="btn btn-primary">
                                        Enviar
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Comment Public -->
                <div class="row py-4 justify-content-center" v-for="comment in comments" :key="comment.id">
                    <div class="col-" hidden>
                        <a href="">
                            <div class="dCirculo">
                                <img class="imgAvatar" src="#" />
                            </div>
                        </a>
                    </div>
                    <div class="col text-left">
                        <span>{{ comment.username }}</span>
                        <span style="font-size:11px;" hidden>{{ comment.created_at.split(" ")[0] }}</span>
                        <p style="color:black;">{{ comment.comment }}</p>
                        <div hidden>
                            <a href="#">Responder</a>
                            <i class="fas fa-thumbs-up btn-like"></i>
                        </div>
                    </div>

                    <div class="col-auto" hidden>
                        <!--  button -->
                        <div class="btn-group">
                            <button type="button" class="btn btnpuntos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <button class="dropdown-item QuestionCommentInap" type="button" data-id="comment.id">
                                    Reportar como inapropiado
                                </button>
                                <button class="dropdown-item QuestionCommentSpam" type="button" data-id="comment.id">
                                    Reportar como spam
                                </button>
                                <hr />
                                <button class="dropdown-item spamComment" type="button" data-id="comment.id">
                                    Eliminar comentario
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Loading -->
    <div v-else v-show="!roundSummaryData.amountQuestions">
        <loadingQuiz />
    </div>

    <div class="row justify-content-center">
        <div class="col- col-sm-12 col-md-8 col-lg-8 col-xl-8 mResumen mt-4" v-if="roundSummaryData.amountQuestions" v-show="isFinishRound">
            <div class="row justify-content-center" v-if="roundSummaryData.score > 1">
                <div class="col- text-center">
                    <p>Ganaste</p>
                    <div class="score">
                        <h1 class="textR">{{ roundSummaryData.score }} EXP</h1>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" v-else>
                <div class="col- text-center">
                    <p>:(</p>
                    <div class="score">
                        <h1 class="textR">{{ roundSummaryData.score }} EXP</h1>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-">
                    <p>Número de preguntas</p>
                    <p>Respuestas correctas</p>
                    <p>Respuestas incorrectas</p>
                    <p>Respuesta en blanco</p>
                    <p>Tiempo promedio</p>
                    <p>Tiempo total</p>
                </div>
                <div class="col-">
                    <p>{{ roundSummaryData.amountQuestions }}</p>
                    <p>{{ roundSummaryData.amountCorrectAnswers }}</p>
                    <p>{{ roundSummaryData.amountWrongAnswers }}</p>
                    <p>{{ roundSummaryData.amountBlankAnswers }}</p>
                    <p>{{ roundSummaryData.averageTime }}</p>
                    <p>{{ roundSummaryData.totalTime }}</p>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col- buttonsR">
                    <div class="btnR" v-on:click="onBackHome">
                        <div class="col- buttonsCircle"><i class="fas fa-list-ol"></i></div>
                    </div>
                </div>
                <div class="col- buttonsR">
                    <div class="btnR" v-on:click="onNextRound">
                        <div class="col- buttonsCircle"><i class="fas fa-redo-alt"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/round.vue";
import scoreHamburger from "@/components/student/score/scoreHamburger.vue";

export default {
    name: 'roundQuiz',
    components: {
        loadingQuiz,
        scoreHamburger,
    },
    data() {
        return {
            userObject: null,
            round: [],
            answers: [],
            getFavorite: [],
            myFavorite: '',
            currentAnswer: [],
            question: null,
            questionOptions: [],
            indexCurrentAnswer: 0,
            progressBar: 0,
            answerSelected: "",
            bloquedAnswers: false,
            showCommentButton: false,
            showFieldComment: false,
            showFavorite: true,
            canGetComments: true,
            canNextQuestion: false,
            message: "",
            timerPerQuestion: "",
            responseTime: 0,
            timer: null,
            responseAnswer: {
                isValid: false,
                validAnswer: "",
            },
            heartColor: {
                favorite: {
                    color: '',
                },
                unfavorite: {
                    color: '',
                },
            },
            inputsbackgroundColor: {
                a: {
                    backgroundColor: "",
                },
                b: {
                    backgroundColor: "",
                },
                c: {
                    backgroundColor: "",
                },
                d: {
                    backgroundColor: "",
                },
                e: {
                    backgroundColor: "",
                },
            },
            isFinishRound: false,
            roundSummaryData: {
                score: 0,
                amountQuestions: 0,
                amountCorrectAnswers: 0,
                amountWrongAnswers: 0,
                amountBlankAnswers: 0,
                averageTime: 0,
                totalTime: 0,
            },
            comments: [],
            typeAnswerGiven: "",
        };
    },
    mounted() {
    },
    async created() {
        await this.getRount();
        await this.chargeData();
        await this.restartTimer();
        await this.getFavorites();
        await this.mFavorite();
    },
    methods: {
        async getRount() {
            const url = this.appDomainApi1 + "round/" + this.$route.params.url;
            await axios
                .get(url, )
                .then((resp) => {
                    this.round = resp.data.round;
                    this.answers = resp.data.questions;
                    this.timerPerQuestion = resp.data.timer_question;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        restartTimer() {
            this.responseTime = this.timerPerQuestion;
            const self = this;
            clearInterval(this.timer);
            if (!this.isRoundFinished()) {
                this.timer = setInterval(function () {
                    self.responseTime -= 1;
                    if (self.responseTime <= 0) {
                        self.finishedTime();
                    }
                }, 1000);
            }
        },
        finishedTime() {
            this.bloquedAnswers = true;
            this.answerSelected = "";
            clearInterval(this.timer);
            this.sendAnswer();
        },
        chargeData() {
            var countAnswersCompleted = this.indexCurrentAnswer + (this.round.amount_questions - this.answers.length);
            this.progressBar = Math.round(
                (countAnswersCompleted * 100) / this.round.amount_questions
            );
            if (this.answers[this.indexCurrentAnswer]) {
                this.currentAnswer = this.answers[this.indexCurrentAnswer] ? this.answers[this.indexCurrentAnswer] : null;
                this.question = this.currentAnswer.question;
                this.questionOptions = this.question.answer;
                this.bloquedAnswers = false;
                this.notifyVisualization();
            } else {
                if (this.isRoundFinished()) {
                    this.showInformationFinalizedRound();
                }
            }
        },
        onNext(e) {
            e.preventDefault();
            if (this.answerSelected === "" && !this.bloquedAnswers) {
                alert("No ha seleccionado ninguna respuesta.");
            } else {
                clearInterval(this.timer);
                this.indexCurrentAnswer += 1;
                this.sendComment();
                this.nextQuestion();
                this.restartTimer();
            }
        },
        nextQuestion() {
            this.chargeData();
            this.answerSelected = "";
            this.showCommentButton = false;
            this.canGetComments = true;
            this.comments = [];
            this.showFieldComment = false;
            this.canNextQuestion = false;
            this.responseAnswer.isValid = false;
            this.responseAnswer.validAnswer = "";
            this.typeAnswerGiven = "";
            this.showFavorite = true;
            this.paintAnswers();
            if (this.isFinishRound === this.round.amount_questions) {
                console.log("Is Finish Round");
            } else {
                this.mFavorite();
                this.getFavorites();
            }
        },
        showComments() {
            if (this.canGetComments) {
                this.canGetComments = false;
                this.getComments();
            }
            this.showFieldComment = !this.showFieldComment;
        },
        getComments() {
            const url =
                this.appDomainApi1 +
                "questions/" +
                this.currentAnswer.question_id +
                "/comments";
            axios
                .get(url)
                .then((resp) => {
                    if (resp.data.code === 200) {
                        this.comments = resp.data.comments;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        selectAnswer(answerSelected) {
            clearInterval(this.timer);
            this.bloquedAnswers = true;
            this.answerSelected = answerSelected;
            this.sendAnswer();
        },
        sendAnswer() {
            const url = this.appDomainApi1 + "round/" + this.round.id + "/answer/" + this.currentAnswer.id;
            axios
                .put(
                    url, {
                        round_id: this.round.id,
                        answer_selected: this.answerSelected.trim(),
                        response_time: this.responseTime,
                    }, )
                .then((resp) => {
                    this.showCommentButton = true;
                    this.canNextQuestion = true;
                    this.responseAnswer.isValid = resp.data.answer.isValid;
                    this.responseAnswer.validAnswer = resp.data.answer.validAnswer;
                    this.paintAnswers();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onSendComment() {
            this.sendComment();
        },
        sendComment() {
            if (this.message !== "") {
                const url =
                    this.appDomainApi1 +
                    "questions/" +
                    this.currentAnswer.question_id +
                    "/comments";
                axios
                    .post(url, {
                        comment: this.message,
                    })
                    .then(() => {
                        this.message = "";
                        this.getComments();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        paintAnswers() {
            if (this.responseAnswer.validAnswer !== "") {
                const questionOptionA = this.questionOptions.a.trim();
                const questionOptionB = this.questionOptions.b.trim();
                const questionOptionC = this.questionOptions.c.trim();
                const questionOptionD = this.questionOptions.d.trim();
                const questionOptionE = this.questionOptions.e.trim();

                if (this.answerSelected !== "") {
                    const selectedAnswer = this.answerSelected.trim();
                    if (selectedAnswer !== this.responseAnswer.validAnswer) {
                        this.typeAnswerGiven = "WrongAnswer";
                        switch (selectedAnswer) {
                            case questionOptionA:
                                this.inputsbackgroundColor.a.backgroundColor = "#ff7c7c";
                                break;
                            case questionOptionB:
                                this.inputsbackgroundColor.b.backgroundColor = "#ff7c7c";
                                break;
                            case questionOptionC:
                                this.inputsbackgroundColor.c.backgroundColor = "#ff7c7c";
                                break;
                            case questionOptionD:
                                this.inputsbackgroundColor.d.backgroundColor = "#ff7c7c";
                                break;
                            case questionOptionE:
                                this.inputsbackgroundColor.e.backgroundColor = "#ff7c7c";
                                break;
                            default:
                                break;
                        }
                    } else {
                        this.typeAnswerGiven = "CorrectAnswer";
                    }
                } else {
                    this.typeAnswerGiven = "TimeOver";
                }

                switch (this.responseAnswer.validAnswer) {
                    case questionOptionA:
                        this.inputsbackgroundColor.a.backgroundColor = "#7cdc7c";
                        break;
                    case questionOptionB:
                        this.inputsbackgroundColor.b.backgroundColor = "#7cdc7c";
                        break;
                    case questionOptionC:
                        this.inputsbackgroundColor.c.backgroundColor = "#7cdc7c";
                        break;
                    case questionOptionD:
                        this.inputsbackgroundColor.d.backgroundColor = "#7cdc7c";
                        break;
                    case questionOptionE:
                        this.inputsbackgroundColor.e.backgroundColor = "#7cdc7c";
                        break;
                    default:
                        break;
                }
            } else {
                this.inputsbackgroundColor.a.backgroundColor = "";
                this.inputsbackgroundColor.b.backgroundColor = "";
                this.inputsbackgroundColor.c.backgroundColor = "";
                this.inputsbackgroundColor.d.backgroundColor = "";
                this.inputsbackgroundColor.e.backgroundColor = "";
            }
        },
        isRoundFinished() {
            var countAnswersCompleted =
                this.indexCurrentAnswer +
                (this.round.amount_questions - this.answers.length);
            this.isFinishRound =
                countAnswersCompleted === this.round.amount_questions;
            return this.isFinishRound;
        },
        showInformationFinalizedRound() {
            const url = this.appDomainApi1 + "round/" + this.round.id;
            axios
                .get(url, )
                .then((resp) => {
                    this.roundSummaryData.score = resp.data.round.score;
                    this.roundSummaryData.amountQuestions =
                        resp.data.round.amount_questions;
                    this.roundSummaryData.amountCorrectAnswers =
                        resp.data.round.correct_answers;
                    this.roundSummaryData.amountWrongAnswers =
                        resp.data.round.wrong_answers;
                    this.roundSummaryData.amountBlankAnswers =
                        resp.data.round.blank_answers;
                    this.roundSummaryData.averageTime = resp.data.round.average_time.toFixed(
                        2
                    );
                    this.roundSummaryData.totalTime = resp.data.round.total_time.toFixed(
                        2
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onNextRound() {
            if (
                this.round.course &&
                this.round.team &&
                this.roundSummaryData.amountQuestions &&
                this.round.course.id &&
                this.round.team.id
            ) {
                let self = this
                const url = this.appDomainApi1 + "round/start";
                axios
                    .post(url, {
                        course: this.round.course.slug,
                        thema: this.round.team.id,
                        amount_questions: this.roundSummaryData.amountQuestions,
                    })
                    .then(function (response) {
                        window.location.href = response.data.url;
                        self.loaded();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert("Error. Vuelva a intentar más tarde. Gracias");
            }
        },
        notifyVisualization() {
            const url = this.appDomainApi1 + "round/answer/" + this.currentAnswer.id;
            axios
                .put(
                    url, {
                        is_displayed: true,
                    }, )
                .then(() => {
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onBackHome() {
            this.$router.push({name: "Courses",});
        },
        addFavorite() {
            const url = this.appDomainApi1 + 'favorite/' + this.question.id + "/addFavorite";
            this.heartColor.favorite.color = '#006df0';
            axios
                .get(url, )
                .then(() => {
                    this.getFavorites();
                    this.mFavorite();
                    this.showFavorite = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getFavorites() {
            const url = this.appDomainApi1 + "favorite/" + this.question.id + "/getFavorites";
            axios
                .get(url, )
                .then((resp) => {
                    if (resp.data.code === 200) {
                        this.getFavorite = resp.data.getFavorite;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mFavorite() {
            const url = this.appDomainApi1 + "favorite/" + this.question.id + "/myFavorite";
            axios
                .get(url, )
                .then((resp) => {
                    if (resp.data.code === 200) {
                        this.myFavorite = resp.data.myFavorite;
                        this.heartColor.favorite.color = '#006df0';
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        daleteFavorite() {
            this.heartColor.unfavorite.color = "";
            const url = this.appDomainApi1 + "favorite/" + this.question.id + "/deleteFavorite";
            axios
                .delete(url, {
                    question: this.quistion_id,
                })
                .then(() => {
                    this.getFavorites();
                    this.mFavorite();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
    },
};
</script>

<style>
.buttonsCircle{
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    margin: 0px -10px 0px 20px;
    overflow: hidden;
    align-items: center;
    justify-items: center;
    display: grid;
    top: 5px;
    cursor: pointer;
}
.text-muted{
    font-size: 12px;
}
/* Theme */
.nav_title{
    color: #ffffff;
}
.nav_title>h1{
    font-size: 1.2rem;
}
.dtema{
    background: #0c2790;
	}
.dtema_icon{
    padding-right: 1rem;
    padding-left: 1rem;
    color: white;
}
/* Actions */
input[type=button]{
	border: none;
	width: inherit;
	white-space: normal;
}
.btn_long{
    padding: .5rem;
    margin: .5rem;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_actions {
    width: 10rem;
    height: 3rem;
    padding: .5rem;
    margin: .5rem;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9EA9A7;
}
.button_click:hover{
    box-shadow: 5px 5px 15px -3px #76767687;
    cursor: pointer;
}

.button_actions_icon {
    padding-left: .5rem;
    padding-right: .5rem;
}

.dPregunta {
    margin-bottom: 15px;
}

.dc1 {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 5px;
}
</style>
