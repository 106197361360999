<template>
<div class="row justify-content-md-center">
    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp dbr">
        </div>
    </div>
    <div class="w-100"></div>
    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp dby dbr">
        </div>
    </div>
    <div class="w-100"></div>

    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp danswer">
        </div>
    </div>
    <div class="w-100"></div>

    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp danswer">
        </div>
    </div>
    <div class="w-100"></div>

    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp danswer">
        </div>
    </div>
    <div class="w-100"></div>

    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp danswer">
        </div>
    </div>
    <div class="w-100"></div>

    <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div class="dp danswer">
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'loadingQuiz'
}
</script>

<style>
.dp {
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 5px;
}

.danswer {
    background: #ffffff;
    height: 1em;
}

.dbr {
    background: #ffffff;
}

.dby {
    height: 10em;
}
</style>
